class KTLayout {
    static _isSidebarCollapse() {
        return document.body.classList.contains('sidebar-collapse');
    }

    static _handleMegaMenu() {
        const megaMenuEl = document.querySelector('#mega_menu');
        if (!megaMenuEl) return;

        const menu = KTMenu.getInstance(megaMenuEl);
        menu.disable();

        setTimeout(() => {
            menu.enable();
        }, 500);
    }

    static _handleSidebar() {
        const sidebarToggle = KTToggle.getInstance(this.sidebarToggleEl);
        sidebarToggle?.on('toggle', () => {
            this.sidebarEl.classList.add('animating');

            this._handleMegaMenu();

            // Callback, der den Toggle-Button aktualisiert
            const updateToggleButton = () => {
                this.sidebarEl.classList.remove('animating');
                const isCollapsed = document.body.classList.contains('sidebar-collapse');
                localStorage.setItem('sidebar-collapsed', isCollapsed ? 'true' : 'false');
                if (isCollapsed) {
                    // Eingeklappt: Klasse "toggle-active" hinzufügen, "active" entfernen
                    this.sidebarToggleEl.classList.add('toggle-active');
                    this.sidebarToggleEl.classList.remove('active');
                } else {
                    // Ausgeklappt: Klasse "active" hinzufügen, "toggle-active" entfernen
                    this.sidebarToggleEl.classList.add('active');
                    this.sidebarToggleEl.classList.remove('toggle-active');
                }
            };

            // Versuche, den transitionEnd-Event abzuwarten ...
            KTDom.transitionEnd(this.sidebarEl, updateToggleButton);
            // ... und setze einen Fallback-Timer, falls transitionEnd nicht feuert
            setTimeout(updateToggleButton, 600);
        });
    }

    static _handleSidebarMenu() {
        const menuEl = document.querySelector('#sidebar_menu');
        const scrollableEl = document.querySelector('#sidebar_scrollable');
        const menuActiveItemEl = menuEl.querySelector(".menu-item.active");

        if (!menuActiveItemEl || KTDom.isVisibleInParent(menuActiveItemEl, scrollableEl)) {
            return;
        }

        scrollableEl.scroll({
            top: KTDom.getRelativeTopPosition(menuActiveItemEl, scrollableEl) - 100,
            behavior: 'instant'
        });
    }

    static init() {
        // Zuerst die benötigten Elemente laden
        this.sidebarEl = document.querySelector('#sidebar');
        this.sidebarWrapperEl = document.querySelector('#sidebar_wrapper');
        this.headerEl = document.querySelector('#header');
        this.sidebarToggleEl = document.querySelector('#sidebar_toggle');

        // Beim Laden der Seite den gespeicherten Zustand wiederherstellen
        const storedState = localStorage.getItem('sidebar-collapsed');
        if (storedState === 'true') {
            document.body.classList.add('sidebar-collapse');
            this.sidebarToggleEl?.classList.add('toggle-active');
            this.sidebarToggleEl?.classList.remove('active');
        } else {
            document.body.classList.remove('sidebar-collapse');
            this.sidebarToggleEl?.classList.add('active');
            this.sidebarToggleEl?.classList.remove('toggle-active');
        }

        if (this.sidebarEl && this.sidebarToggleEl) {
            this._handleSidebar();
            this._handleSidebarMenu();
        }
    }

    static isSidebarCollapse() {
        return this._isSidebarCollapse();
    }
}

KTDom.ready(() => {
    KTLayout.init();
});

export default KTLayout;