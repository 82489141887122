'use strict'

import $ from "jquery";
import {asSocket} from "../websocket/client.js";

console.log('uiHandler/core.js')

// Callback-Funktion für MutationObserver, Überwachung von Änderungen an der Seite für Triggern von Aktionen
const mutationCallback = function (mutationsList, observer) {
    // Überprüfen, ob das data-bs-theme Attribut geändert wurde
    mutationsList.forEach(mutation => {
        // Überprüfen, ob das data-module-url Attribut geändert wurde
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-url') {
            console.log('data-url changed');
            console.log("Start");
            setTimeout(() => {
                initDataObjects();
            }, 500);
            console.log("Ende (wird sofort ausgeführt)");
        }
    });
};

function handleClick(event) {
    console.log(event.target.localName);

    // Suche das übergeordnete <a>-Element
    let parentAnchor = event.target.closest('a');
    let href = null;

    switch (event.target.localName) {
        case 'a':
            href = parentAnchor.href;

            if (!parentAnchor) return;
            if (!href) return;

            if ($(event.target).hasClass('fg-button ui-button ui-state-default')) {
                event.preventDefault();
                return;
            }
            break;
        case 'div':
            break;

        case 'img':
            break;

        case 'span':
            href = parentAnchor.href;

            event.preventDefault();

            let target = event.target;
            parentAnchor = target.closest('a');

            if (!parentAnchor) {
                return;
            }

            if (!href) {
                return;
            }

            let isFrontend = ui === 'frontend';
            let isBackend = ui === 'backend';
            let isNavigatingToFrontend = href.startsWith(window.location.origin + '/frontend');
            let isNavigatingToBackend = href.startsWith(window.location.origin + '/backend');

            // **Wenn der Nutzer von Backend zu Frontend oder umgekehrt wechselt → WebSocket schließen & HTTP-Redirect**
            if ((isBackend && isNavigatingToFrontend) || (isFrontend && isNavigatingToBackend)) {
                if (asSocket && typeof asSocket.close === 'function') {
                    asSocket.close();
                }
                window.location.href = href;
                return;
            }

            // **Wenn der Link zu /frontend/... oder /backend/... gehört, dann über WebSocket senden**
            if (isNavigatingToFrontend || isNavigatingToBackend) {
                let parAnc = $(parentAnchor);
                let content = $('#content');
                content
                    .attr('data-url', href)
                    .attr('data-module', parAnc.attr('data-module'))
                    .attr('data-path', parAnc.attr('data-path'));

                if (asSocket && typeof asSocket.send === 'function') {
                    asSocket.send('viewRequest', href);
                } else {
                    window.location.href = href;
                }
                return;
            }

            // **Alle anderen Links per HTTP aufrufen**
            console.log("Direkte Navigation per HTTP:", href);
            window.location.href = href;
            break;

        case 'button':
            let objectId = $(event.target).data('doObjectid');
            let actionType = $(event.target).data('doType');

            console.log("objectId:", objectId);
            console.log("actionType:", actionType);

            if (objectId !== undefined && actionType === 'editEntry') {
                let dataObject = getDataObject(objectId);
                if (dataObject && typeof dataObject.renderEditEntryFormBody === 'function') {
                    dataObject.renderEditEntryFormBody();
                }
            }
            break;
    }
}

const observer = new MutationObserver(mutationCallback);
const observerTargetHTML = document.querySelector('html');
const observerTargetoutputViewController = document.getElementById('content');

window.observer = observer;
window.observerTargetHTML = observerTargetHTML;
window.observerTargetoutputViewController = observerTargetoutputViewController;
window.handleClick = handleClick;
export {observer, observerTargetHTML, observerTargetoutputViewController, handleClick};
